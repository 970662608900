<template>
  <div class="columns mt-3 is-multiline">
    <div class="column is-12">
      <div class="form-container">
        <ValidSelect
          rules="required"
          label="¿Cómo se cobrará el capital?"
          fieldClass="label-big"
          placeholder="Selecciona una manera de cobro..."
          v-model="tratamiento_capital.tipo_pago"
          @input="changeManeraCobro"
          :data="getTiposPago"
          :disabled="producto_monto.producto_objeto && producto_monto.producto_objeto.tipo_credito.tipo.includes('Arrendamiento')"
          show="alias"
          realValue="clave"
          :expanded="true"
        />
        <!-- <div
          class="columns mt-3"
          v-if="tratamiento_capital.tipo_pago == 'regulares'"
        >
          <div class="column is-2">
            <ValidInput
              rules="required|min_value:1"
              label="Número de Periodos"
              v-model="tratamiento_capital.numero_tiempo"
              type="number"
              placeholder="7"
            />
          </div>
          <div class="column">
            <ValidSelect
              rules="required"
              label="Tipo de período"
              placeholder="Selecciona una periodo regular..."
              v-model="tratamiento_capital.periodo"
              :expanded="true"
              :data="getPeriodosRegulares"
              realValue="id"
              show="nombre"
            />
          </div>
        </div>-->
        <div
          class="columns mt-3"
          v-if="tratamiento_capital.tipo_pago == 'vencimiento'"
        >
          <div class="column is-2">
            <ValidInput
              rules="required|min_value:1"
              :label="
                tratamiento_capital.periodo == 'months'
                  ? 'Número de meses'
                  : 'Número de días'
              "
              v-model="tratamiento_capital.numero_tiempo"
              type="number"
              placeholder="7"
              @directInput="selectDate"
            />
          </div>
          <div class="column is-2">
            <valid-datepicker
              rules="required"
              label="Fecha de entrega"
              placeholder="selecciona una fecha..."
              v-model="tratamiento_capital.fecha_inicio"
              @directInput="changeFechaInicio"
            />
          </div>
          <div class="column">
            <ValidSelect
              rules="required"
              label="Tiempo"
              placeholder="Selecciona una opción..."
              v-model="tratamiento_capital.periodo"
              @directInput="selectDate"
              :expanded="true"
              :data="tiposTiempo"
              show="nombre"
              realValue="id"
            />
          </div>
          <div class="column">
            <valid-datepicker
              rules="required"
              label="o bien, selecciona una fecha"
              placeholder="selecciona una fecha..."
              v-model="tratamiento_capital.fecha_vencimiento"
              @directInput="dateToDays"
            />
          </div>
        </div>
        <div
          class="columns is-multiline mt-12"
          v-if="tratamiento_capital.tipo_pago == 'irregulares'"
        >
          <div v-if="!esArrendamiento && !esCreditoGrupal" class="column">
            <b-button
              :type="
                !pasivo 
                  ? esArrendamiento ? 'is-arrendamiento' : 'is-primary' 
                  : 'is-pasivo'
              "
              outlined
              @click="addPeriodo"
              >Añadir periodo</b-button
            >
          </div>
          <div class="column md:text-right text-left">
            <b-button
              class="ml3"
              outlined
              @click="toggleModalMultiplesAmortizaciones(true)"
              >Múltiples amortizaciones</b-button
            >
          </div>
        </div>
        <div
          class="columns is-multiline"
          v-if="tratamiento_capital.tipo_pago == 'irregulares'"
        >
          <div v-show="!esCreditoGrupal" class="column is-4">
            <valid-datepicker
              rules="required"
              label="Fecha de entrega"
              placeholder="selecciona una fecha..."
              v-model="tratamiento_capital.fecha_inicio"
            />
          </div>
        </div>
        <b-table
          :data="amortizaciones"
          v-if="!esCreditoGrupal && tratamiento_capital.tipo_pago == 'irregulares'"
        >
          <b-table-column
            field="fecha_pago"
            label="Fecha de pago"
            v-slot="props"
          >
            <valid-datepicker
              rules="required"
              :min-date="getMinDate(props)"
              :disabled="tratamiento_capital.isRegular"
              placeholder="selecciona una fecha..."
              v-model="props.row.fecha_pago"
              position="is-top-right"
            />
          </b-table-column>
          <b-table-column
            field="abono_capital"
            label="Abono a capital"
            v-slot="props"
          >
            <valid-currency-input
              rules="required|currency"
              placeholder="0.00"
              :disabled="tratamiento_capital.isRegular"
              v-model="props.row.abono_capital"
            />
          </b-table-column>
          <b-table-column field="btn_delete" label v-slot="props">
            <template slot="header">
              <b-tooltip
                label="Eliminar todas las amortizaciones"
                type="is-dark"
                position="is-top"
              >
                <b-button
                  type="is-danger"
                  icon-right="trash"
                  class="ml-0"
                  @click="deleteAllRows"
                />
              </b-tooltip>
            </template>
            <b-button
              type="is-danger"
              icon-right="trash"
              v-if="props.index != 0 && !tratamiento_capital.isRegular"
              @click="
                deleteRow(tratamiento_capital.tabla_irregular.data, props.index)
              "
            />
          </b-table-column>
          <template slot="footer">
            <th class="has-text-grey-darker">Insoluto:</th>
            <th
              :class="{
                'has-text-danger': insolutoRestante < 0,
                'has-text-success': insolutoRestante == 0,
                'has-text-warning': insolutoRestante > 0
              }"
            >
              {{ insolutoRestante | currency | hp }}
            </th>
            <th />
          </template>
        </b-table>

        <b-table
          :data="amortizaciones"
          v-if="esCreditoGrupal && tratamiento_capital.tipo_pago == 'irregulares'"
        >
          <b-table-column
            field="fecha_pago"
            label="Fecha de pago"
            v-slot="props"
          >
            <valid-datepicker
              rules="required"
              :min-date="getMinDate(props)"
              :disabled="tratamiento_capital.isRegular"
              placeholder="selecciona una fecha..."
              v-model="props.row.fecha_pago"
              position="is-top-right"
            />
          </b-table-column>
        </b-table>

      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="modalActive"
        :active.sync="modalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="toggleModalMultiplesAmortizaciones(false)"
      >
        <ModalMultiplesAmortizaciones
          :monto="producto_monto.monto"
          :periodos="periodos"
          :productoFinanciero="producto_monto.producto_financiero"
          :interes_ordinario="interes_ordinario"
          :tipo_pago="tratamiento_capital.tipo_pago"
          :arrendamiento="esArrendamiento"
          :valorResidual="this.producto_monto.valor_residual"
          :enganche="this.producto_monto.enganche"
          :tipo_interes_ordinario="producto_monto.producto_objeto.tipo_interesordinario_id"
          :esCreditoGrupal="esCreditoGrupal"
          :cantidades_grupo="cantidades_grupo"
          @amortizaciones="loadAmortizaciones"
          @isregular="setRegular"
          @fecha_inicio="
            fecha =>
              (tratamiento_capital.fecha_inicio = $moment(fecha).toDate())
          "
          @fecha_primer_pago="
            fecha =>
              (tratamiento_capital.fecha_primer_pago = $moment(fecha).toDate())
          "
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import ModalMultiplesAmortizaciones from "@/components/SolicitarDisposicionSteps/ModalMultiplesAmortizaciones";

export default {
  name: "tratamiento_capital",
  props: {
    producto_monto: Object,
    pasivo: Boolean,
    tratamiento_capital: Object,
    interes_ordinario: Object,
    modalMultiplesAmortizaciones: Boolean,
    cantidades_grupo: Array,
    esCreditoGrupal: Boolean,
  },
  data() {
    return {
      modalActive: false
    };
  },
  components: {
    ValidCurrencyInput,
    ValidSelect,
    ValidInput,
    ValidDatepicker,
    ModalMultiplesAmortizaciones
  },
  methods: {
    getMinDate(row) {
      if (row.index == 0) {
        return this.$moment(this.tratamiento_capital.fecha_inicio).add(1, 'days').toDate();
      }
      return this.$moment(this.amortizaciones[row.index - 1].fecha_pago).add(1, 'days').toDate();
    },
    setRegular(val) {
      if (!val) {
        this.tratamiento_capital.isRegular = false;
      } else {
        this.tratamiento_capital.isRegular = val.isRegular;
        this.tratamiento_capital.numero_tiempo = val.numero_tiempo;
        this.tratamiento_capital.periodo = val.periodo;
        this.tratamiento_capital.mesForzado = val.mesForzado;
        this.tratamiento_capital.mismoDia = val.mismoDia;
      }
    },
    changeManeraCobro() {
      switch (this.tratamiento_capital.tipo_pago) {
        // case "irregulares":
        //   this.tratamiento_capital.fecha_inicio = this.$moment(
        //     this.tratamiento_capital.tabla_irregular.data[0].fecha_pago
        //   ).toDate();
        //   break;
        case "vencimiento":
          this.tratamiento_capital.isRegular = false;
          
          this.tratamiento_capital.fecha_vencimiento = this.$moment(
            this.getSofom.fecha_cierre
          )
            .add(1, "days")
            .toDate();
          this.tratamiento_capital.fecha_inicio = this.$moment(
            this.getSofom.fecha_cierre
          )
            .add(1, "days")
            .toDate();
          break;
      }
    },
    loadAmortizaciones(amortizaciones) {
      this.tratamiento_capital.tabla_irregular.data = amortizaciones;
    },
    /* 
      En caso de ser pago unico de capital al vencimiento, 
      al seleccionar el tipo de tiempo y el número se actualiza la fecha
    */
    selectDate(numero) {
      if (!isNaN(numero)) {
        this.tratamiento_capital.numero_tiempo = parseInt(numero);
      } else {
        this.tratamiento_capital.periodo = numero;
      }
      if (
        this.tratamiento_capital.periodo &&
        this.tratamiento_capital.numero_tiempo
      ) {
        let periodo = this.tratamiento_capital.periodo == "days" ? "d" : "M";
        let fecha = this.$moment(this.tratamiento_capital.fecha_inicio).add(
          this.tratamiento_capital.numero_tiempo,
          periodo
        );
        this.tratamiento_capital.fecha_vencimiento = fecha.toDate();
        this.tratamiento_capital.plazo_dias =
          this.tratamiento_capital.periodo == "days"
            ? this.tratamiento_capital.numero_tiempo
            : this.$moment(this.tratamiento_capital.fecha_vencimiento).diff(this.tratamiento_capital.fecha_inicio, 'days');
      }
    },
    changeFechaInicio(fechaDeInicio) {
      if (
        !this.tratamiento_capital.periodo ||
        !this.tratamiento_capital.numero_tiempo
      ) {
        return;
      }

      const periodo = this.tratamiento_capital.periodo == "days" ? "d" : "M";
      const fecha = this.$moment(fechaDeInicio).add(
        this.tratamiento_capital.numero_tiempo,
        periodo
      );

      this.tratamiento_capital.fecha_vencimiento = fecha.toDate();
      this.selectDate(this.tratamiento_capital.periodo);
    },
    /* 
      En caso de ser pago unico de capital al vencimiento, 
      al seleccionar la fecha se calcula el número de días
    */
    dateToDays(date) {
      let fecha_vencimiento = this.$moment(date);
      let fecha_ini = this.$moment(this.tratamiento_capital.fecha_inicio);
      this.tratamiento_capital.periodo = "days";
      this.tratamiento_capital.numero_tiempo = fecha_vencimiento.diff(
        fecha_ini,
        "days"
      );
      this.selectDate(this.tratamiento_capital.periodo);
    },
    /*
      En caso de ser periodos irregulares, se agrega una nueva fila
      con la fecha de un mes siguiente al ultimo registro
    */
    addPeriodo() {
      let ultimo = this.tratamiento_capital.tabla_irregular.data[
        this.tratamiento_capital.tabla_irregular.data.length - 1
      ];
      let fecha_pago = null;
      if (ultimo.fecha_pago != null) {
        fecha_pago = this.$moment(ultimo.fecha_pago)
          .add(1, "M")
          .toDate();
      }
      this.tratamiento_capital.tabla_irregular.data.push({
        fecha_pago: fecha_pago,
        abono_capital: 0
      });
    },
    toggleModalMultiplesAmortizaciones(estado) {
      this.modalActive = estado;
    },
    deleteRow(array, index) {
      array.splice(index, 1);
    },
    deleteAllRows() {
      this.tratamiento_capital.tabla_irregular.data = [
        { fecha_pago: null, abono_capital: 0 }
      ];
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getTiposPago",
      "getPeriodosRegulares",
      "getTiposTiempo"
    ]),
    ...mapGetters(["getSofom"]),
    insolutoRestante() {
      let valor = 0;
      this.tratamiento_capital.tabla_irregular.data.forEach(amort => {
        valor += amort.abono_capital;
      });
      
      let restante = 0;
      if (this.esArrendamiento) {
        restante = this.tratamiento_capital.tabla_irregular.data[this.tratamiento_capital.tabla_irregular.data.length-1].restante;
      }

      let insoluto = this.producto_monto.monto - valor - restante;
      insoluto = Math.trunc(insoluto * 100) / 100;

      if (insoluto < 0.1) {
        insoluto = 0;
      }
      
      return insoluto;
    },
    valor_residual() {
      return (this.producto_monto.valor_residual / 100) * this.producto_monto.monto;
    },
    tiposTiempo() {
      let tipos = this.getTiposTiempo;
      if (tipos.length == 3) {
        tipos.pop();
      }
      return tipos;
    },
    esArrendamiento() {
      return this.producto_monto && this.producto_monto?.producto_objeto ? this.producto_monto?.producto_objeto?.tipo_credito.tipo.includes("Arrendamiento") : false;
    },
    periodos() {
      return this.getPeriodosRegulares.filter(periodo => !['mensual (Día variable)', 'mensual (Mismo día calculo variable)'].includes(periodo.nombre));
    },
    amortizaciones() {
      if (this.tratamiento_capital.isRegular) {
        // eslint-disable-next-line no-unused-vars
        const [_, ...amortizaciones] = this.tratamiento_capital.tabla_irregular.data;
        return amortizaciones;
      }

      return this.tratamiento_capital.tabla_irregular.data;
    }
  },
  mounted() {
    this.loadAmortizaciones(this.tratamiento_capital.tabla_irregular.data);
  },
  created() {
    if (this.tratamiento_capital.fecha_vencimiento == null) {
      this.tratamiento_capital.fecha_vencimiento = this.$moment(
        this.getSofom.fecha_cierre
      ).toDate();
    } else {
      this.tratamiento_capital.fecha_vencimiento = this.$moment(
        this.tratamiento_capital.fecha_vencimiento
      ).toDate();
    }
    if (this.tratamiento_capital.fecha_inicio == null) {
      this.tratamiento_capital.fecha_inicio = this.$moment(
        this.getSofom.fecha_cierre
      ).toDate();
    } else {
      this.tratamiento_capital.fecha_inicio = this.$moment(
        this.tratamiento_capital.fecha_inicio
      ).toDate();
    }
    if (! this.tratamiento_capital.plazo_dias) {
      this.tratamiento_capital.plazo_dias =
            this.tratamiento_capital.periodo == "days"
              ? this.tratamiento_capital.numero_tiempo
              : this.$moment(this.tratamiento_capital.fecha_vencimiento).diff(this.tratamiento_capital.fecha_inicio, 'days');
    }
    this.tratamiento_capital.tabla_irregular.data = this.tratamiento_capital.tabla_irregular.data.map(
      data => {
        return {
          abono_capital: data.abono_capital,
          fecha_pago: this.$moment(data.fecha_pago).toDate()
        };
      }
    );
  }
};
</script>
